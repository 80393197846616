import React from 'react';
import {IconContext} from 'react-icons';
import {FaTwitter, FaLinkedin, FaGithub, FaCodepen, FaEnvelope} from 'react-icons/fa';
import './socialMedia.scss';

export const SocialMedia = () => {
    return (
        <div className="social-media">
            <IconContext.Provider value={{color: 'white', style: { fontSize: '2rem'}}}>

                <a className="mr-4"
                   target="_blank"
                   rel="noreferrer"
                   href="https://www.linkedin.com/in/marcin-madej-8861bb118/"
                   data-cy="socialMedia__linkedin"
                >
                    <FaLinkedin />
                </a>

                <a className="mr-4"
                   target="_blank"
                   rel="noreferrer"
                   href="https://twitter.com/mejdej93"
                   data-cy="socialMedia__twitter"
                >
                    <FaTwitter />
                </a>

                <a className="mr-4"
                   target="_blank"
                   rel="noreferrer"
                   href="https://github.com/mejdej93"
                   data-cy="socialMedia__github"
                >
                    <FaGithub />
                </a>

                <a className="mr-4"
                   target="_blank"
                   rel="noreferrer"
                   href="https://codepen.io/mejdej93"
                   data-cy="socialMedia__codepen"
                >
                    <FaCodepen />
                </a>

                <a href="mailto:marcinmadej1993@gmail.com">
                    <FaEnvelope />
                </a>

            </IconContext.Provider>
        </div>
    );
};
