import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import {BLOCKS} from '@contentful/rich-text-types';
import PageContext from 'contexts/PageContext';
import {SocialMedia} from '../components/SocialMedia/SocialMedia';

const WelcomePage = ({pageContext, ...props}) => {

    const heroClassNames = classNames({
        'welcomePage-hero': true
    });

    const {
        data: {
            allContentfulWelcomePage: {
                nodes: welcomePageNodes
            }
        }
    } = props;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: node => `<h1 class="has-text-light welcomePage-coverTitle title is-1 is-size-3-mobile mb-2">${node.content[0].value}</h1>`,
            [BLOCKS.HEADING_2]: node => `<h1 class="has-text-light welcomePage-coverTitle subtitle is-3 is-size-5-mobile">${node.content[0].value}</h1>`,
            [BLOCKS.LIST_ITEM]: node => `<li class="subtitle is-4">${node.content[0].content[0].value}</li>`
        },
    };

    const getChapterContent = (index) => {
        return documentToHtmlString(JSON.parse(welcomePageNodes[0].content[index].childContentfulChapterContentRichTextNode.content), options);
    };

    const chapters = [
        getChapterContent(0),
        getChapterContent(1)
    ];

    return (
        <PageContext.Provider value={pageContext}>

            <Helmet title="Portfolio - Marcin Madej"/>

            <div className={heroClassNames}>
                <div className="hero-container">

                    <div className="hero-content" dangerouslySetInnerHTML={{__html: chapters[0] + chapters[1]}} />

                    <SocialMedia />
                </div>
            </div>

        </PageContext.Provider>
    );
};

export const pageQuery = graphql`
    query {
        allContentfulWelcomePage {
            nodes {
                content {
                    childContentfulChapterContentRichTextNode {
                        content
                    }
                }
            }
        }
    }
`;

export default WelcomePage;
